import { withAuthUser, AuthAction } from 'next-firebase-auth'
import FirebaseSignIn from '../../components/FirebaseSignIn'

const Loader = () => <h1>Loading...</h1>

const Login = () => <FirebaseSignIn />

export default withAuthUser({
    whenAuthed: AuthAction.REDIRECT_TO_APP,
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
    whenUnauthedAfterInit: AuthAction.RENDER,
    LoaderComponent: Loader,
})(Login)
