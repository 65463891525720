import { init } from 'next-firebase-auth'

const firebaseAuthInitialization = () => {
  init({
    authPageURL: '/login',
    appPageURL: '/',
    loginAPIEndpoint: '/api/login', // required
    logoutAPIEndpoint: '/api/logout', // required
    // Required in most cases.
    firebaseAdminInitConfig: {
      credential: {
        projectId: 'loupe-1138',
        clientEmail: 'firebase-adminsdk-xuz49@loupe-1138.iam.gserviceaccount.com',
        // The private key must not be accesssible on the client side.
        privateKey: Buffer.from(process.env.FIREBASE_PRIVATE_KEY_BASE64 || "", 'base64').toString(),
      },
      databaseURL: 'https://loupe-1138.firebaseio.com',
    },
    firebaseClientInitConfig: {
      apiKey: "AIzaSyBkzqTvKhueapbUnmmAlAkSOvtlmLK229I",
      authDomain: "loupe-1138.firebaseapp.com",
      databaseURL: "https://loupe-1138.firebaseio.com",
      projectId: "loupe-1138",
      storageBucket: "loupe-1138.appspot.com",
      messagingSenderId: "755948731047",
      appId: "1:755948731047:web:17456678da7711c56a7982",
      measurementId: "G-TC2ESHV4HD",
    },
    cookies: {
      name: 'LoupeAdminWebApp', // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: true, // set this to false in local (non-HTTPS) development
      signed: true,
    },
  })
}

export default firebaseAuthInitialization
